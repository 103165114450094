
<template>
  <v-dialog v-model="dialog" width="400">
    <v-card>
      <v-card-title>{{ $t("editName") }}</v-card-title>
      <v-card-text>
        <v-text-field v-model="new_name" outlined dense></v-text-field>
      </v-card-text>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon id="iconClose">mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="dialog = false"
          outlined
          style="height: 25px; width: 100px"
          elevation="0"
          >{{ $t("cancel") }}</v-btn
        >
        <v-btn
          @click="rename"
          :disabled="old_name === route + '/' + new_name"
          :outlined="old_name === route + '/' + new_name"
          style="
            width: 100px !important;
            color: #363533;
            height: 25px;
            padding-top: 10px !important;
          "
          elevation="0"
        >
          <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
          {{ $t("save", { name: $t("") }) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "RenameDialog",
  props: ["route"],
  data() {
    return {
      dialog: false,
      old_name: null,
      new_name: null,
      extension: null,
    };
  },
  methods: {
    open(file) {
      //console.log(file);
      this.old_name = file;
      let splitFile = file.split("/");
      this.extension = splitFile[splitFile.length - 1].split(".")[1];
      this.new_name = splitFile[splitFile.length - 1].split(".")[0];
      this.dialog = true;
    },
    ...mapActions("files", ["renameFiles"]),
    rename() {
      //console.log(this.new_name, this.file, this.old_name, this.route);
      this.renameFiles({
        new: this.extension
          ? this.new_name + "." + this.extension
          : this.new_name,
        old: this.old_name,
        route: this.route,
      }).then((data) => {
        this.dialog = false;
        this.$emit("update", data);
      });
    },
  },
};
</script>